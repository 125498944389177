import React, { useEffect, useRef, useState } from "react";
import { motion, useMotionValue, useAnimationFrame } from "framer-motion";
import "./OurAssociationsVelocity.scss";

import home1 from "../assets/partners/partners/home1.webp";
import home2 from "../assets/partners/partners/home2.webp";
import home3 from "../assets/partners/partners/home3.webp";
import home4 from "../assets/partners/partners/home4.webp";
import home5 from "../assets/partners/partners/home5.webp";
import home6 from "../assets/partners/partners/home6.webp";
import home7 from "../assets/partners/partners/home7.webp";

const partnerData = [
    { partnerImg: home1 },
    { partnerImg: home2 },
    { partnerImg: home3 },
    { partnerImg: home4 },
    { partnerImg: home5 },
    { partnerImg: home6 }, 
    { partnerImg: home2 },
    { partnerImg: home3 },
    { partnerImg: home4 },
    { partnerImg: home5 },
    { partnerImg: home6 },
  ];

export default function OurAssociationsVelocity() {
  const x = useMotionValue(0);
  const duplicatedPartners = [...partnerData, ...partnerData];
  const containerRef = useRef(null);
  const paused = useRef(false);
  const speed = 100; 
  const [resetX, setResetX] = useState(0);

  
  const updateResetX = () => {
    if (containerRef.current) {
      
      setResetX(containerRef.current.scrollWidth / 2);
    }
  };

   
  useEffect(() => {
    updateResetX();
    window.addEventListener("resize", updateResetX);
    const timeout = setTimeout(updateResetX, 500);
    return () => {
      window.removeEventListener("resize", updateResetX);
      clearTimeout(timeout);
    };
  }, []);

  useAnimationFrame((time, delta) => {
    if (!paused.current) {
      const moveBy = (speed * delta) / 1000;
      x.set(x.get() - moveBy);
      if (x.get() <= -resetX) {
        x.set(0);
      }
    }
  });

  const handleMouseEnter = () => {
    paused.current = true;
  };
  const handleMouseLeave = () => {
    paused.current = false;
  };
  const handleClick = () => {
    paused.current = !paused.current;
  };

  return (
    <section className="aboutus-partners">
      <h2 className="aboutus-partners-heading">Our Associations</h2>
      <div
        className="carousel-wrapper"
        style={{ overflow: "hidden" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <motion.div
          className="marquee"
          ref={containerRef}
          style={{ x, display: "flex" }}
        >
          {duplicatedPartners.map((partner, index) => (
            <div key={index} style={{ flexShrink: 0 }}>
              <img
                src={partner.partnerImg}
                alt="Our partners"
                className="carousel-img"
                onLoad={updateResetX}
              />
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}
