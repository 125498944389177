"use client";
import React from "react";
import { motion } from "motion/react";

const BorderBeam = ({
  size = 50,
  duration = 6,
  delay = 0,
  colorFrom = "#ffaa40",
  colorTo = "#9c40ff",
  transition,
  className,
  style,
  reverse = false,
  initialOffset = 0,
}) => {
  const containerStyle = {
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: "inherit",
    border: "2px solid #c9d8fcbd", //DN: Controls the width or thickness of the beam also
    maskClip: "padding-box, border-box",
    maskComposite: "intersect",
    maskImage:
      "linear-gradient(transparent, transparent), linear-gradient(#000, #000)",
  };

  //DN:  Animated beam style
  const beamStyle = {
    position: "absolute",
    width: size,
    aspectRatio: "1 / 1",
    offsetPath: `rect(0 auto auto 0 round ${size}px)`,
    "--color-from": colorFrom,
    "--color-to": colorTo,
    background: `linear-gradient(to left, ${colorFrom}, ${colorTo}, transparent)`,
    ...style,
  };

  //DN: Defining the motion transition with infinite looping
  const motionTransition = {
    repeat: Infinity,
    ease: "linear",
    duration,
    delay: -delay,
    ...transition,
  };

  //DN: Define the animation keyframes based on the reverse flag
  const animation = reverse
    ? { offsetDistance: [`${100 - initialOffset}%`, `${-initialOffset}%`] }
    : { offsetDistance: [`${initialOffset}%`, `${100 + initialOffset}%`] };

  return (
    <div style={containerStyle}>
      <motion.div
        className={className}
        style={beamStyle}
        initial={{ offsetDistance: `${initialOffset}%` }}
        animate={animation}
        transition={motionTransition}
      />
    </div>
  );
};

export default BorderBeam;