import "./Policy.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function Privacy () {
    return (
        <>
         <Helmet>
                <title>Privacy Ploicy - Cograd</title>
                <meta
                name="description"
                content="Learn about Cograd's privacy policy and how we collect, use, and protect your personal information to ensure your data is handled securely."
                />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Header />
            <div className="policy_section">
            <h1>Privacy Policy</h1>
                        
                        <div class="meta-info">
                            <p><strong>Effective Date:</strong> 09/09/2020</p>
                            <p><strong>Last Updated:</strong> 03/01/2024</p>
                        </div>
                        
                        <section>
                            <h2>Introduction</h2>
                            <p>Welcome to Supervision Educare Private Limited (Cograd)! At Cograd, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website or use our services. By accessing or using our website and services, you consent to the practices described in this Privacy Policy.</p>
                        </section>
                        
                        <section>
                            <h2>Information We Collect</h2>
                            <p>We may collect the following types of information when you interact with our website or use our services:</p>
                            
                            <h3>2.1. Personal Information:</h3>
                            <ul>
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Contact information (such as phone number)</li>
                                <li>User-generated content (such as comments or reviews)</li>
                                <li>Other information you voluntarily provide to us</li>
                            </ul>
                            
                            <h3>2.2. Non-Personal Information:</h3>
                            <ul>
                                <li>Log data (including your IP address, browser type, operating system, and referring web page)</li>
                                <li>Cookies and similar tracking technologies</li>
                            </ul>
                        </section>
                        
                        <section>
                            <h2>How We Use Your Information</h2>
                            <p>We may use your information for the following purposes:</p>
                            
                            <h3>3.1. Providing Services:</h3>
                            <ul>
                                <li>To provide, maintain, and improve our website and services</li>
                                <li>To communicate with you about your account and any transactions</li>
                                <li>To respond to your inquiries and provide consultancy or customer support</li>
                            </ul>
                            
                            <h3>3.2. Personalization:</h3>
                            <ul>
                                <li>To personalize and enhance your experience on our website or products</li>
                                <li>To recommend relevant content or services based on your preferences and usage</li>
                            </ul>
                            
                            <h3>3.3. Analytics and Research:</h3>
                            <ul>
                                <li>To analyze the usage of our website and services</li>
                                <li>To conduct research and gather insights to improve our offerings</li>
                            </ul>
                            
                            <h3>3.4. Marketing and Communications:</h3>
                            <ul>
                                <li>To send you promotional materials and updates about our products and services</li>
                                <li>To inform you of important changes to our website or services</li>
                                <li>To showcase your feedback or reviews (with your consent) taken from us as testimonials</li>
                            </ul>
                        </section>
                        
                        <section>
                            <h2>How We Share Your Information</h2>
                            <p>We may share your information in the following circumstances:</p>
                            
                            <h3>4.1. Service Providers:</h3>
                            <ul>
                                <li>We may share your information with third-party service providers who assist us in delivering our services (e.g., cloud providers, payment processors, and customer support services).</li>
                            </ul>
                            
                            <h3>4.2. Legal Compliance:</h3>
                            <ul>
                                <li>We may disclose your information when required by law, regulation, or legal process.</li>
                            </ul>
                            
                            <h3>4.3. Business Transfers:</h3>
                            <ul>
                                <li>In the event of a merger, acquisition, or sale of all or a part of our assets, your information may be transferred as part of the transaction.</li>
                            </ul>
                            
                            <h3>4.4. Consent:</h3>
                            <ul>
                                <li>We may share your information with your consent or as otherwise disclosed at the time of data collection.</li>
                            </ul>
                        </section>
                        
                        <section>
                            <h2>Your Choices and Rights</h2>
                            <p>You have the following rights regarding your personal information:</p>
                            
                            <ul>
                                <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
                                <li><strong>Correction:</strong> You can request that we correct or update your personal information.</li>
                                <li><strong>Deletion:</strong> You can request the deletion of your personal information, subject to legal limitations.</li>
                                <li><strong>Withdrawal of Consent:</strong> You can withdraw your consent for certain data processing activities.</li>
                            </ul>
                            
                            <p>To exercise any of these rights or for any privacy-related inquiries, please contact us at <a href="mailto:cograd.office@gmail.com" class="highlight">cograd.office@gmail.com</a></p>
                        </section>
                        
                        <section>
                            <h2>Security</h2>
                            <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of data transmission over the Internet is 100% secure, and we cannot guarantee the security of your information.</p>
                        </section>
                        
                        <section>
                            <h2>Changes to this Privacy Policy</h2>
                            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will post the revised Privacy Policy on our website, and the updated version will be effective as of the Effective Date.</p>
                        </section>

                        <section>
                            <h2>Contact Us</h2>
                            <p>If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at <a href="mailto:cograd.office@gmail.com" class="highlight">cograd.office@gmail.com</a></p>
                        </section>
            </div>
            


        <Footer />
        </>
           
    );
};