import "./Policy.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function Terms() {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Cograd</title>
        <meta
          name="description"
          content="Learn about Cograd's Terms and Conditions that govern access to and use of our website, products, and services."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Header />
      <div className="policy_section">
        <h1>Terms and Conditions</h1>
        <div className="meta-info">
          <p>
            <strong>Effective Date:</strong> 09/09/2020
          </p>
          <p>
            <strong>Last Updated:</strong> 09/09/2024
          </p>
        </div>
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to Supervision Educare Private Limited ("Cograd," "Company," "we," "our," "us"). These Terms and Conditions ("Terms") govern your access to and use of our website, products, and services ("Services"). By accessing or using our Services, you agree to comply with and be bound by these Terms. Please read them carefully.
          </p>
        </section>
        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms, including any additional terms and conditions and policies referenced herein.
          </p>
        </section>
        {/* <section>
          <h2>2. Services Provided</h2>
          <p>
            Cograd provides software, web development and app development services, including but not limited to website development, custom software development, custom enterprise solutions, maintenance, cloud services, API integration, and related consulting services. The scope of services will be defined in a separate agreement or Statement of Work ("SOW") or Weblinear Analytics shared during initiation of project.
          </p>
        </section> */}
        <section>
          <h2>2. User Responsibilities</h2>
          <p>You agree to:</p>
          <ul>
            <li>Provide accurate and complete information as requested by the Company.</li>
            <li>Use the Services in compliance with all applicable laws and regulations.</li>
            <li>
              Not engage in any activity that could harm, disrupt, or interfere with the Services, including unauthorized access to the Company’s systems.
            </li>
          </ul>
        </section>
        <section>
          <h2>3. Intellectual Property Rights</h2>
          <p>
            All intellectual property rights in the Services, including but not limited to software, code, designs, trademarks, and documentation, are owned by Cograd or its licensors. You are granted a non-exclusive, non-transferable, limited license to use the Services solely for your internal business purposes.
          </p>
        </section>
        <section>
          <h2>4. Confidentiality</h2>
          <p>
            Both parties agree to keep confidential all non-public information disclosed during the course of the relationship, including but not limited to trade secrets, business plans, and client data. Confidential information shall not be disclosed to any third party without the prior written consent of the disclosing party.
          </p>
        </section>
        <section>
          <h2>5. Payment Terms</h2>
          <p>
            Payment for Services shall be made in accordance with the terms set forth in the separate agreement or SOW. Invoices are due upon receipt unless otherwise specified. Late payments may incur interest at the rate of 35% per month.
          </p>
        </section>
        <section>
          <h2>6. Warranties and Disclaimers</h2>
          <p>
            The Company warrants that the Services will be performed in a professional and workmanlike manner. Except as expressly provided herein, the Services are provided "as is" without any warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
          </p>
        </section>
        <section>
          <h2>7. Limitation of Liability</h2>
          <p>
            To the maximum extent permitted by law, the Company shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or related to the use of the Services, even if the Company has been advised of the possibility of such damages. The Company shall not entertain any claims or refunds under these Terms. All payments made for the Services are final and non-refundable.
          </p>
        </section>
        <section>
          <h2>8. Termination</h2>
          <p>
            Either party may terminate the relationship upon 60 days' written notice. The Company may terminate the Services immediately if you breach any of these Terms. Upon termination, all licenses granted to you will cease, and you must immediately stop using the Services.
          </p>
        </section>
        <section>
          <h2>9. Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of Uttar Pradesh, India, without regard to its conflict of law principles. Any disputes arising out of or in connection with these Terms shall be resolved exclusively in the courts of Uttar Pradesh, India.
          </p>
        </section>
        <section>
          <h2>10. Amendments</h2>
          <p>
            We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the Services after such changes constitutes your acceptance of the new Terms.
          </p>
        </section>
        <section>
          <h2>11. Severability</h2>
          <p>
            If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
          </p>
        </section>
        <section>
          <h2>12. Entire Agreement</h2>
          <p>
            These Terms, together with any other agreements or policies referenced herein, constitute the entire agreement between you and the Company and supersede all prior agreements, understandings, or representations, whether written or oral.
          </p>
        </section>
        <section>
          <h2>13. Contact Information</h2>
          <p>If you have any questions or concerns about these Terms, please contact us at:</p>
          <p>
            <a href="mailto:cograd.office@gmail.com" className="highlight">
              cograd.office@gmail.com
            </a>
          </p>
          <p>Or write to us at:</p>
          <p>
          Supervision Educare Private Limited, <br/>
          Cograd Tech Village, Sector-101, <br/>
          Noida Extension, Uttar Pradesh 201306
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
}
