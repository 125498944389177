// ContactUs
import React, { useState, useEffect } from "react";
import "./ContactUs.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    how: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
    if (errors[e.target.name]) {
      setErrors(prev => ({
        ...prev,
        [e.target.name]: ''
      }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: '', email: '', message: '' };

    if (!formData.name.trim()) {
      newErrors.name = 'Please enter your name';
      isValid = false;
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Please enter your email';
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
      isValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Please enter your message';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage('');

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const templateParams = {
      to_name: 'Admin',
      message: `
      Name: ${formData.name}
      Email: ${formData.email}
      Phone: ${formData.phone}
      Source: ${formData.how}
      Message: ${formData.message}
      `,
    };

    try {
      const response = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      if (response.status === 200) {
        setFormData({
          name: '',
          email: '',
          phone: '',
          how: '',
          message: ''
        });
        setSuccessMessage('Message sent successfully! We will get back to you soon.');
      }
    } catch (error) {
      console.error('Failed to send message:', error);
      setErrors(prev => ({
        ...prev,
        message: 'Failed to send message. Please try again or contact us directly at cograd.office@gmail.com'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Cograd</title>
        <meta
          name="description"
          content="Get in touch with Cograd for innovative and affordable education solutions. Discover our vision, services, and partnership opportunities. Contact us now for more information."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Header />
      <div className="contact-section">
        <div className="overlay"></div>
        <div className="contact-container">
          <div className="contact-form">
            <h2>Get in <span>Touch</span></h2>
            <p>
              We are here to assist you with any inquiries you may have. Please
              fill out the form below and we'll get back to you shortly.
            </p>
            <form onSubmit={handleSubmit} noValidate>
              <div className="form-group">
                <input 
                  type="text" 
                  name="name" 
                  placeholder="Your Name *" 
                  onChange={handleChange} 
                  value={formData.name}
                  required 
                  disabled={isLoading}
                />
                {errors.name && <div className="text-danger" style={{ fontSize: '14px', marginTop: '5px' }}>{errors.name}</div>}
              </div>

              <div className="form-group">
                <input 
                  type="email" 
                  name="email" 
                  placeholder="Your Email *" 
                  onChange={handleChange} 
                  value={formData.email}
                  required 
                  disabled={isLoading}
                />
                {errors.email && <div className="text-danger" style={{ fontSize: '14px', marginTop: '5px' }}>{errors.email}</div>}
              </div>

              <div className="form-group">
                <input 
                  type="tel" 
                  name="phone" 
                  placeholder="Your Phone Number (optional)" 
                  onChange={handleChange} 
                  value={formData.phone}
                  disabled={isLoading}
                />
              </div>

              <div className="form-group">
                <select 
                  name="how" 
                  onChange={handleChange} 
                  value={formData.how}
                  disabled={isLoading}
                >
                  <option value="">How did you find us?</option>
                  <option value="google">Google</option>
                  <option value="social">Social Media</option>
                  <option value="friend">From a Friend</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="form-group">
                <textarea 
                  name="message" 
                  placeholder="Your Message *" 
                  onChange={handleChange} 
                  value={formData.message}
                  required
                  disabled={isLoading}
                  rows="5"
                ></textarea>
                {errors.message && <div className="text-danger" style={{ fontSize: '14px', marginTop: '5px' }}>{errors.message}</div>}
              </div>

              {successMessage && (
                <div style={{ color: '#28a745', marginBottom: '15px', fontSize: '14px' }}>
                  {successMessage}
                </div>
              )}

              <button 
                type="submit" 
                disabled={isLoading}
                className={isLoading ? 'loading' : ''}
              >
                {isLoading ? 'Sending...' : 'SEND'}
              </button>
            </form>
            <div className="contact-info">
              <div className="row">
                <p className="col-12">
                  <strong>PHONE</strong>
                  <br />
                  <a href="tel:+919220253001">+91-9220253001</a>{' '}
                </p>
                <p className="col-12">
                  <strong>EMAIL</strong>
                  <br />
                  <a href="mailto:cograd.office@gmail.com">cograd.office@gmail.com</a>
                </p>
              </div>
              
            </div>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1752.1026232006907!2d77.42057248950003!3d28.563598858474233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef00384ca85f%3A0x5ac41558b8334d2a!2sCograd%20Tech%20Village!5e0!3m2!1sen!2sin!4v1719158047209!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Cograd Tech Village Location"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
