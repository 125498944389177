import React, { useEffect } from 'react';
import './PopupModal.scss';
import { Xmark , ArrowUpRight } from 'iconoir-react';
import cogradLogo from '../assets/logo-white.webp';
import sharkTankImage from '../assets/cg_shark.jpg';

const PopupModal = ({ isOpen, onClose }) => {
  // Disable body scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleSharkTankClick = () => {
    window.open("https://www.sonyliv.com/shows/shark-tank-india-1700000741/smart-learning-healthy-eating-and-wellness-1090469757?watch=true", "_blank");
    handleClose();
  };

  const handleFellowshipClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSffTQsBZwZizYfSGFD0EBk0wswkqi1sgmr5AeOi_xVymPT80w/viewform",
      "_blank"
    );
    handleClose();
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div 
        className="popup-modal"
        style={{
          backgroundImage: `linear-gradient(rgba(159, 156, 183, 0.8), rgba(0, 0, 0, 0.85)), url(${sharkTankImage})`,
        }}
      >
        <button className="close-button" onClick={handleClose}>
          <Xmark width={24} height={24} />
        </button>
        
        <div className="modal-content">
          <div className="logo-container">
            <img src={cogradLogo} alt="Cograd Logo" className="logo" />
          </div>
          
          <h1 className="modal-title">Transform Your Learning Journey</h1>
          <p className="modal-subtitle">
            Democratizing education through innovative and affordable solutions that empower learners worldwide.
          </p>
          
          {/* Buttons arranged in a row on larger screens; wrap on smaller */}
          <div className="button-row">
            <button className="feature-button-2" onClick={handleSharkTankClick}>
              <span>Watch Our Pitch</span>
              <ArrowUpRight width={16} height={16} />
            </button>

            <button className="feature-button" onClick={handleFellowshipClick}>
              <span>Cograd Fellowship Program</span>
              <ArrowUpRight width={16} height={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
