import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import CogradFellowship from './pages/CogradFellowship';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/privacy-policy" element={<Privacy/>} />
          <Route path="/terms-conditions" element={<Terms/>} />
          <Route path="/fellowship" element={<CogradFellowship/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
