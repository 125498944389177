import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./AboutUs.scss"; 
import AboutHistory from "../components/AboutHistory";
import OurAssociationsVelocity from "../components/OurAssociationsVelocity";
import mission from "../assets/about-us/mission_11zon.webp";
import vision from "../assets/about-us/vision_11zon.webp";
import team from "../assets/our-team.webp";
import innovation from "../assets/innovation.webp";
import integrity from "../assets/integrity.webp";
import excellence from "../assets/excellence.webp";
import himanshu from "../assets/himanshu.webp";
import saurabh from "../assets/saurabh.webp";
import j1 from "../assets/journey/j1.webp";
import j2 from "../assets/journey/j2.webp";
import j3 from "../assets/journey/j3.webp";
import j4 from "../assets/journey/j4.webp";
import j5 from "../assets/journey/j5.webp";
import media1 from "../assets/media/1.webp";
import media2 from "../assets/media/2.webp";
import media3 from "../assets/media/3.webp";
import media4 from "../assets/media/4.webp";
import media5 from "../assets/media/5.webp";
import media6 from "../assets/media/6.webp";
import media7 from "../assets/media/7.webp";
import media8 from "../assets/media/8.webp";
import media9 from "../assets/media/9.webp";
import media10 from "../assets/media/10.webp";
import media from "../assets/media/media_11zon.webp";
import mobileMedia from "../assets/mobileMedia_11zon.webp";
import { FaLinkedin, FaLongArrowAltRight } from "react-icons/fa";
import { ArrowUpRight } from 'iconoir-react';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
  { innovativeText: "Innovation", image: innovation },
  { innovativeText: "Integrity", image: integrity },
  { innovativeText: "Excellence", image: excellence },
  // Add more values and corresponding images as needed
];

const data1 = [
  {
    username: "Himanshu Chaurasia",
    description:
      "I had the vision to build amazing schools in India. I handle and guide the team as the executive",
    linkedin: "https://www.linkedin.com/in/chaurasia-himanshu/",
    role: "Founder, CEO",
    image: himanshu,
  },
  {
    username: "Saurabh Yadav",
    description:
      "I joined the Cograd team as COO at a very early age. I handle the operations side of Cograd",
    linkedin: "https://www.linkedin.com/in/saurabh-yadav-8048a013b/",
    role: "Co-Founder, COO",
    image: saurabh,
  },
];

const mediaData = [
  {
    id: 1,
    image: media1,
    link: "https://yourstory.com/hindi/this-startup-is-giving-hi-tech-education-to-children-in-villages-cograd",
    alt: "Cograd on Your story"
  },
  {
    id: 2,
    image: media2,
    link: "https://navbharattimes.indiatimes.com/education/exam-results/iit-and-nit-students-startup-cograde-medha-ai-offline-system-transforming-picture-of-education-in-indian-villages/articleshow/115406844.cms?s",
    alt: "Cograd on India Times"
  },
  {
    id: 3,
    image: media3,
    link: " #",
    alt: "Cograd on Omor Uajala"
  },
  {
    id: 4,
    image: media4,
    link: " #",
    alt: "Cograd on Hindustan"
  },
  {
    id: 5,
    image: media5,
    link: "https://youtu.be/xP6glTTCXKk?si=jY5T7r_wv4yWkdI4",
    alt: "Cograd on Josh Talks"
  },
  {
    id: 6,
    image: media6,
    link: " #",
    alt: "Cograd on Dainik Jagaran"
  },
  {
    id: 7,
    image: media7,
    link: "https://www.moneycontrol.com/entertainment/shark-tank-india-4-cograd-founders-questioned-for-lack-of-focus-secure-rs-1-crore-deal-amid-tough-negotiations-article-12951529.html/amp",
    alt: "Cograd on Money Control"
  },
  {
    id: 8,
    image: media8,
    link: "https://indianexpress.com/article/entertainment/television/shark-tank-india-4-cograd-pitch-ritesh-agarwal-vineeta-singh-9859041/",
    alt: "Cograd on Indian Express"
  },{
    id: 9,
    image: media9,
    link: "https://www.news18.com/movies/shark-tank-india-vineeta-singh-goes-silent-as-ritesh-agarwal-ignores-her-in-front-of-pitchers-9244108.html",
    alt: "Cograd on News 18"
  },{
    id: 10,
    image: media10,
    link: "https://www.zeebiz.com/startups/news-shark-tank-india-4-this-education-startup-tells-ritesh-agarwal-they-are-oyo-of-schools-bags-three-sharks-deal-348962",
    alt: "Cograd on ZeeBiz"
  },
];

const journeyData = [
  {
    imgSrc: j1,
    title: "Step 1: Inception",
    description: "Our journey began with a vision to transform education.",
  },
  {
    imgSrc: j2,
    title: "Step 2: First Milestone",
    description:
      "We reached our first milestone by launching our first school.",
  },
  {
    imgSrc: j3,
    title: "Step 3: Growth",
    description: "Expanding our reach and impacting more lives.",
  },
  {
    imgSrc: j4,
    title: "Step 4: Innovation",
    description: "Incorporating innovative teaching methods and technologies.",
  },
  {
    imgSrc: j5,
    title: "Step 5: Future",
    description:
      "Continuing to strive for excellence and accessibility in education.",
  },
];

const AboutUsGeanco = ({ data }) => {
  return (
    <div className="aboutus-card">
      <div className="aboutus-card-inner">
        <div className="aboutus-card-image">
          <img
            src={data.image}
            alt="secondaryimage"
            className="aboutus-card-image-img"
          />
        </div>
        <span className="aboutus-card-heading">{data.username}</span>
        <span className="aboutus-card-description">{data.description}</span>
        <div className="aboutus-card-role-container">
          <div className="aboutus-card-role-inner">
            <span className="aboutus-card-role">{data.role}</span>
            <FaLongArrowAltRight className="aboutus-card-arrow" />
          </div>
          <a href={data.linkedin} target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="aboutus-card-linkedin " />
          </a>
        </div>
      </div>
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 4,  
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const AboutUs = () => {
  return (
    <div className="aboutus-container">
      <Helmet>
        <title>About Cograd - Innovative and Affordable Education</title>
        <meta
          name="description"
          content="Discover the vision of Cograd's founders for building accessible and innovative schools in India. Learn about our journey, services, and partnership. Contact us for more information."
        />
      </Helmet>
      <Header />
      {/* <div className="aboutus-hero"> */}
        {/* <div className="aboutus-hero-image-1">
          <div className="flip-card-inner">
            <div className="front">
              <div className="image-overlay">
                <h1>Mission</h1>
              </div>
              <img src={mission} alt="Mission" className="aboutus-hero-image" />
            </div>
            <div className="back">
              <p>
                Our mission is to provide quality education that is innovative,
                affordable, and accessible to all.
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="aboutus-hero-secondary">
          <div className="aboutus-hero-image-2">
            <div className="flip-card-inner">
              <div className="front">
                <div className="image-overlay">
                  <h1>Vision</h1>
                </div>
                <img
                  src={vision}
                  alt="Vision"
                  className="aboutus-hero-image"
                />
              </div>
              <div className="back">
                <p>
                  Our vision is to revolutionize education with cutting-edge
                  technology and personalized support.
                </p>
              </div>
            </div>
          </div>
          <div className="aboutus-hero-image-3">
            <div className="flip-card-inner">
              <div className="front">
                <div className="image-overlay">
                  <h1>Our Team</h1>
                </div>
                <img src={team} alt="Team" className="aboutus-hero-image" />
              </div>
              <div className="back">
                <p>
                  Meet our dedicated team of professionals who are committed to
                  making a difference in education.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
{/* NEW CARD STYLE FOR ABOUT US STARTS HERE */}


{/* <div className="row"> 

        <div className="col-lg-6 col-sm-12 d-flex justify-content-center mt-4">
          
                  <div class = "about-cards">
                        <img src={mission} alt="Cograd's mission" />
                        <div className="card-content">
                          <h2>
                            Mission
                          </h2>
                          <p>
                            Our mission is to provide quality education that is innovative,
                            affordable, and accessible to all.
                          </p>
                          <a href="#" className="button">
                            Find out more 
                          </a>
                        </div>
                  </div>
        </div>

        <div className="col-lg-6 col-sm-12 d-flex justify-content-center mt-4">
            <div className = "about-cards">
                  <img src={vision} alt="Cograd's vision" />
                  <div classNmae="card-overlay"></div>
                  <div className="card-content">
                    <h2>
                      Vision
                    </h2>
                    <p>
                      Our vision is to revolutionize education with cutting-edge
                      technology and personalized support.
                    </p>
                    <a href="#" className="button">
                      Find out more 
                    </a>
                  </div>
            </div>
        </div>

</div> */}
{/* NEW CARD STYLE FOR ABOUT US ENDS HERE */}
      {/* Values Section */}
      {/* <div className="aboutus-values">
        <h4 className="aboutus-values-heading">Our Values</h4>
        <div className="aboutus-values-list">
          <Suspense fallback={<div>Loading feed...</div>}>
            {data.map((d, index) => (
              <div key={`featuresList${index}`} className="aboutus-values-item">
                  <div className="values-front">
                    <h1 className="aboutus-values-text">{d.innovativeText}</h1>
                  </div>
              </div>
            ))}
          </Suspense>
        </div>
      </div> */}

      <AboutHistory />

      {/* founders section */}
      <div className="aboutus-founders">
        <h2 className="aboutus-founders-heading">Our Founders</h2>
        <div className="aboutus-founders-list">
          <Suspense fallback={<div>Loading feed...</div>}>
            {data1.map((d, index) => (
              <AboutUsGeanco data={d} key={"teamList" + index} />
            ))}
          </Suspense>
        </div>
      </div>

      {/* Mission vission old cards */}
      <div className="aboutus-hero">
        <div className="aboutus-hero-image-1">
          <div className="flip-card-inner">
            <div className="front">
              <div className="image-overlay">
                <h2>Mission</h2>
              </div>
              <img src={mission} alt="Mission" className="aboutus-hero-image" />
            </div>
            <div className="back">
              <p>
                Our mission is to provide quality education that is innovative,
                affordable, and accessible to all.
              </p>
            </div>
          </div>
        </div>
        <div className="aboutus-hero-secondary">
          <div className="aboutus-hero-image-2">
            <div className="flip-card-inner">
              <div className="front">
                <div className="image-overlay">
                  <h2>Vision</h2>
                </div>
                <img
                  src={vision}
                  alt="Vision"
                  className="aboutus-hero-image"
                />
              </div>
              <div className="back">
                <p>
                  Our vision is to revolutionize education with cutting-edge
                  technology and personalized support.
                </p>
              </div>
            </div>
          </div>
          <div className="aboutus-hero-image-3">
            <div className="flip-card-inner">
              <div className="front">
                <div className="image-overlay">
                  <h2>Our Team</h2>
                </div>
                <img src={team} alt="Cograd team" className="aboutus-hero-image" />
              </div>
              <div className="back">
                <p>
                  Meet our dedicated team of professionals who are committed to
                  making a difference in education.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


          {/* Adding mission and vission  */}
{/* <div className="container mission-container">
          <div className="row mission-row">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="col-lg-6 col-md-6 mb-4">
                  <div className="card mission-card" style={{ position: 'relative', overflow: 'hidden' }}>
                    <img src={mission} alt={`Card ${index + 1}`} className="card-img-top" />
                    <div className="mission-card-body" style={{ textAlign: 'center' }}>
                    <h5 className="mission-card-title">Card Title {index + 1}</h5>
                    <p className="mission-card-text" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', opacity: 0, transition: 'opacity 0.3s' }}>
                      This is a description for card {index + 1}.
                    </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
</div>
 */}

      

      {/* partners section */}
      <OurAssociationsVelocity/>

      {/* journey section */}
      {/* <div className="aboutus-journey">
        <div className="aboutus-journey-inner">
          <div className="aboutus-journey-content">
            <h2 className="aboutus-journey-heading">Our Journey</h2>
            <div className="aboutus-journey-timeline">
              {journeyData.map((journey, index) => (
                <div className="aboutus-journey-step" key={index}>
                  <img
                    src={journey.imgSrc}
                    alt={`journey ${index + 1}`}
                    className="aboutus-journey-image"
                  />
                  <div className="aboutus-journey-text">
                    <h3>{journey.title}</h3>
                    <p>{journey.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* Social Presence section */}
      <div className="social-presence social-presence-medias">
        <h2 className="aboutus-founders-heading">Our Social Presence</h2>
        {/* Add clickable cards */}
        <div className="container media-container">
          <div className="row align-items-stretch">

            <div className="col-lg-6 col-md-12 mb-4">
              <div className="media-card">
                <img src={media} alt="Cograd in news" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="row">
                {mediaData.map((item) => (
                  <div key={item.id} className="col-lg-6 col-md-6 col-sm-6 mb-4 media-image-cards-right">
                    <div className="media-card">
                      <img src={item.image} alt={item.alt} />
                      <div className="card-body">
                        <a href={item.link} className="btn btn-primary button-design" target="_blank" rel="noopener noreferrer">
                          {/* Read more  */}
                          <ArrowUpRight />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row">
          <div className="col-lg-6 col-md-12 mb-4">
              <iframe className="media-box card-body"
                src="https://www.youtube.com/embed/xP6glTTCXKk"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
            <iframe className="media-box card-body"
              src="https://www.youtube.com/embed/jZAr0WVOsGI"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
            <iframe className="media-box card-body"
              src="https://www.youtube.com/embed/RLCRm3QvMm0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
            <iframe className="media-box card-body"
              src="https://www.youtube.com/embed/Qh8ip-3QHTk"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;   
