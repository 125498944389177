import React, { useState, useEffect } from "react";
import "./AboutHistory.scss";
import ah2019  from "../assets/AboutHistory/ah2019.webp";
import ah2020  from "../assets/AboutHistory/ah2020.webp";
import ah2021  from "../assets/AboutHistory/ah2021.webp";
import ah2022  from "../assets/AboutHistory/ah2022.webp";
import ah2023  from "../assets/AboutHistory/ah2023.webp";
import ah2024  from "../assets/AboutHistory/ah2024.webp";

import { ArrowLeft, ArrowUpRight, ArrowRight } from 'iconoir-react';

const stories = [
  {
    id: 1,
    year: "2019",
    backgroundImage: ah2019,
    content1: "First DreamAdvance Camp",
    content2: "Trained 1000+ Students on Emerging Tech",
    content3: "Reached 10+ Schools",
  },
  {
    id: 2,
    year: "2020",
    backgroundImage: ah2020,
    content1: "LMS for 30+ Schools",
    content2: "Conceptualized CoGrad School's",
    content3: "Reached 50+ Villages",
  },
  {
    id: 3,
    year: "2021",
    backgroundImage: ah2021,
    content1: "First Cograd School in Etawah",
    content2: "Started Skilling Rural Youth",
    content3: "Reached 100+ Villages",
  },
  {
    id: 4,
    year: "2022",
    backgroundImage: ah2022,
    content1: "Onboarded 3 new schools",
    content2: "Introduced Innovation Classes",
    content3: "Reached 200+ villages",
  },
  {
    id: 5,
    year: "2023",
    backgroundImage: ah2023,
    content1: "Onboarded 8 new schools",
    content2: "Trained 20000+ Youths on Emerging Tech",
    content3: "Conceptualized Medha AI",
  },
  {
    id: 6,
    year: "2024",
    backgroundImage: ah2024,
    content1: "Medha AI Beta version launched",
    content2: "Collaborated with NVS & KVS",
    content3: "Got Funded in Shark Tank India",
  },
];

const AboutHistory = () => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  let touchStartX = 0;
  let touchEndX = 0;

  const nextStory = () => {
    setCurrentStoryIndex((prevIndex) => (prevIndex + 1) % stories.length);
  };

  const prevStory = () => {
    setCurrentStoryIndex((prevIndex) => (prevIndex - 1 + stories.length) % stories.length);
  };

  const goToStory = (index) => {
    setCurrentStoryIndex(index);
  };

  const handleTouchStart = (e) => {
    touchStartX = e.changedTouches[0].screenX;
  };

  const handleTouchMove = (e) => {
    touchEndX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      nextStory();
    }

    if (touchEndX - touchStartX > 50) {
      prevStory();
    }
  };

  useEffect(() => {
    const element = document.querySelector('.about-history');
    element.addEventListener('touchstart', handleTouchStart);
    element.addEventListener('touchmove', handleTouchMove);
    element.addEventListener('touchend', handleTouchEnd);

    return () => {
      element.removeEventListener('touchstart', handleTouchStart);
      element.removeEventListener('touchmove', handleTouchMove);
      element.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <div className="about-history">
      <h1>Our Journey</h1>
      <div
        className="story-content"
        style={{
          backgroundImage: `url(${stories[currentStoryIndex]?.backgroundImage})`,
          position: 'relative',
        }}
      >
        <div className="journey-overlay"> 
        <div className="story">  
          <ul>
            {Object.keys(stories[currentStoryIndex])
              .filter((key) => key.startsWith("content"))
              .map((key, index) => (
                <li key={index}>{stories[currentStoryIndex][key]}</li>
              ))}
          </ul>
        </div>
        </div>
      </div>
      <div className="navigation-container">
        <div className="pagination-nav">
          {stories.map((story, index) => (
            <a
              key={story.id}
              className={`pagination-button ${
                index === currentStoryIndex ? "active" : ""
              }`}
              onClick={() => goToStory(index)}
            >
              {story.year}
            </a>
          ))}
        </div>
        <div className="row">
          <ArrowLeft className="next-story-button" onClick={prevStory} />
          <ArrowRight className="next-story-button" onClick={nextStory} />
        </div>
      </div>
      <div className="journey_700">
        <div className="story">  
          <ul>
            {Object.keys(stories[currentStoryIndex])
              .filter((key) => key.startsWith("content"))
              .map((key, index) => (
                <li key={index}>{stories[currentStoryIndex][key]}</li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutHistory;