// COGRAD FELLOWSHIP
import React, { useEffect, useState, useRef } from "react";
import "./CogradFellowship.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ArrowUpRight } from 'iconoir-react';
import { FaDollarSign, FaGraduationCap, FaChartLine, FaGlobeAmericas, FaPlay, FaArrowRight, FaArrowLeft, FaPaperPlane } from 'react-icons/fa';
import { RiDoubleQuotesL } from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

const CogradFellowship = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    education: '',
    experience: '',
    interests: '',
    additional: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    education: '',
    interests: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  
  const testimonials = [
    {
      text: "The Cograd Fellowship transformed my career trajectory. The mentorship and research opportunities I received helped me develop cutting-edge skills that set me apart in the industry.",
      author: "Dr. Sarah Johnson",
      role: "AI Researcher, Former Cograd Fellow"
    },
    {
      text: "Being part of the Cograd Fellowship community opened doors I never thought possible. The collaborative environment and networking opportunities connected me with leaders in the field.",
      author: "Michael Chen",
      role: "Data Scientist, Fellowship Alum"
    },
    {
      text: "The structured research program and personalized guidance from experienced mentors at Cograd helped me publish my first academic paper. This fellowship is invaluable for aspiring researchers.",
      author: "Priya Sharma",
      role: "PhD Candidate, Cograd Fellow 2024"
    },
    {
      text: "What sets Cograd apart is the emphasis on both theoretical knowledge and practical application. I gained hands-on experience working on real-world projects that significantly boosted my portfolio.",
      author: "James Wilson",
      role: "Machine Learning Engineer, Former Fellow"
    }
  ];

  // Create a ref for the fellowship form section
  const fellowshipFormRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 800 });
    AOS.refresh();
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleApplyClick = () => {
    // Scroll to the fellowship form section instead of opening Google Form
    fellowshipFormRef.current.scrollIntoView({ 
      behavior: 'smooth', 
      block: 'start' 
    });
  };
  
  const handleEligibilityClick = (e) => {
    e.preventDefault();
    // Scroll to the fellowship form section
    fellowshipFormRef.current.scrollIntoView({ 
      behavior: 'smooth', 
      block: 'start' 
    });
  };
  
  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
  };
  
  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Clear error when user types
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ""
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    
    if (!formData.education.trim()) {
      newErrors.education = "Education background is required";
    }
    
    if (!formData.interests.trim()) {
      newErrors.interests = "Research interests are required";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsLoading(true);
    
    try {
      // Simulate form submission with timeout
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Reset form after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        education: "",
        experience: "",
        interests: "",
        additional: ""
      });
      
      setSuccessMessage("Your application has been submitted successfully! We'll contact you soon.");
      
      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
      
    } catch (error) {
      setErrors({
        ...errors,
        form: "Failed to submit application. Please try again."
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fellowship-page">
      <Helmet>
        <title>Cograd Fellowship Program - Research Stipend & Mentorship</title>
        <meta name="description" content="Join the Cograd Fellowship Program for financial support, expert mentorship, career growth opportunities, and international projects." />
      </Helmet>
      
      <Header />
      
      {/* Hero Section with YouTube Video */}
      <section className="fellowship-hero">
        <div className="container">
          <div className="hero-content" data-aos="fade-up">
            <h1>Cograd Fellowship Program</h1>
            <p className="hero-subtitle">Join a community of innovators shaping the future of education and technology</p>
          </div>
          
          <div className="video-container" data-aos="fade-up" data-aos-delay="100">
            <div className="play-button-wrapper">
              <a 
                href="https://www.youtube.com/embed/7WlOKaR_rgM" 
                target="_blank"
                rel="noopener noreferrer"
                className="play-button"
              >
                <FaPlay />
              </a>
            </div>
            <iframe 
              src="https://www.youtube.com/embed/7WlOKaR_rgM" 
              title="Cograd Fellowship Program" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
              style={{ aspectRatio: '9/16', maxWidth: '100%', height: '100%' }}
            ></iframe>
          </div>
          
          <div className="moving-line-container" data-aos="fade-up" data-aos-delay="200">
            <div className="moving-line">
              <div className="moving-text">
                <span>Join Cograd Fellowship Program</span>
                <span>Apply Now for 2024-25 Cohort</span>
                <span>Research Stipend Available</span>
                <span>Connect with Industry Leaders</span>
              </div>
            </div>
          </div>
          
          <div className="decorative-shape shape-1"></div>
          <div className="decorative-shape shape-2"></div>
          
          <div className="hero-cta" data-aos="fade-up" data-aos-delay="300">
            <button className="apply-button" onClick={handleApplyClick}>
              <span>Apply Now</span>
              <ArrowUpRight width={16} height={16} />
            </button>
          </div>
        </div>
      </section>
      
      {/* Key Benefits Section */}
      <section className="key-benefits">
        <div className="container">
          <h2 className="section-title" data-aos="fade-up">Key Benefits</h2>
          
          <div className="benefits-grid">
            <div className="benefit-card" data-aos="fade-up" data-aos-delay="100">
              <div className="benefit-icon">
                <FaDollarSign />
              </div>
              <h3>Financial Support</h3>
              <p>Receive a competitive research stipend to support your academic journey and innovative projects.</p>
            </div>
            
            <div className="benefit-card" data-aos="fade-up" data-aos-delay="200">
              <div className="benefit-icon">
                <FaGraduationCap />
              </div>
              <h3>Expert Mentorship</h3>
              <p>Connect with industry leaders and academic experts who will guide your research and professional growth.</p>
            </div>
            
            <div className="benefit-card" data-aos="fade-up" data-aos-delay="300">
              <div className="benefit-icon">
                <FaChartLine />
              </div>
              <h3>Career Growth</h3>
              <p>Access specialized training, professional development workshops, and networking opportunities.</p>
            </div>
            
            <div className="benefit-card" data-aos="fade-up" data-aos-delay="400">
              <div className="benefit-icon">
                <FaGlobeAmericas />
              </div>
              <h3>Global Opportunities</h3>
              <p>Work on international projects and expand your network with researchers and professionals worldwide.</p>
            </div>
          </div>
        </div>
        
        <div className="decorative-shape shape-3"></div>
      </section>
      
      {/* How to Apply Section */}
      <section className="how-to-apply">
        <div className="container">
          <h2 className="section-title" data-aos="fade-up">How to Apply</h2>
          
          <div className="steps-container">
            <div className="step" data-aos="fade-up" data-aos-delay="100">
              <div className="step-number">1</div>
              <div className="step-content">
                <h3>Fill out the online application</h3>
                <p>Complete our comprehensive application form and provide details about your research interests. <button className="link-button" onClick={handleEligibilityClick}>Learn more about fellowship eligibility</button>.</p>
              </div>
            </div>
            
            <div className="step" data-aos="fade-up" data-aos-delay="200">
              <div className="step-number">2</div>
              <div className="step-content">
                <h3>Submit CV & research proposal</h3>
                <p>Share your academic and professional background along with a well-structured research proposal that aligns with Cograd's mission and vision.</p>
              </div>
            </div>
            
            <div className="step" data-aos="fade-up" data-aos-delay="300">
              <div className="step-number">3</div>
              <div className="step-content">
                <h3>Attend an interview</h3>
                <p>Selected candidates will be invited for an interview with our fellowship committee to discuss their application and research interests in detail.</p>
              </div>
            </div>
            
            <div className="step" data-aos="fade-up" data-aos-delay="400">
              <div className="step-number">4</div>
              <div className="step-content">
                <h3>Final selection announcement</h3>
                <p>Successful applicants will be notified and welcomed into the Cograd Fellowship Program with a formal onboarding process.</p>
              </div>
            </div>
          </div>
          
          <div className="apply-cta" data-aos="fade-up">
            <button className="apply-button pulse" onClick={handleApplyClick}>
              <span>Apply Now</span>
              <ArrowUpRight width={16} height={16} />
            </button>
            
            <p className="deadline-notice">Applications for the 2024-25 cohort are now open!</p>
          </div>
        </div>
        
        <div className="decorative-shape shape-4"></div>
        <div className="decorative-shape shape-5"></div>
      </section>
      
      {/* Fellowship Application & Testimonials Section */}
      <section className="fellowship-testimonials" ref={fellowshipFormRef}>
        <div className="container py-5">
          <div className="section-title text-center mb-5">
            <h2>Fellowship Experiences</h2>
            <p>Learn from the experiences of our fellows and join our growing community</p>
          </div>

          <div className="fellowship-success-stats">
            <div className="stats-container">
              <div className="stat-item">
                <h3>100+</h3>
                <p>Cograd Fellows</p>
              </div>
              <div className="stat-item">
                <h3>85%</h3>
                <p>Career Advancement</p>
              </div>
              <div className="stat-item">
                <h3>40+</h3>
                <p>Research Projects</p>
              </div>
            </div>
          </div>
          
          <div className="fellowship-content-wrapper">
            {/* Fellowship Application Form */}
            <div className="fellowship-form">
              <h3>Apply for Fellowship</h3>
              <p>Join our community of researchers and innovators. Fill out the form below to apply for the Cograd Fellowship Program.</p>
              
              {successMessage && (
                <div className="success-message">
                  {successMessage}
                </div>
              )}
              
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name *"
                    value={formData.name}
                    onChange={handleInputChange}
                    disabled={isLoading}
                    required
                  />
                  {errors.name && <div className="error-message">{errors.name}</div>}
                </div>
                
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email *"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={isLoading}
                    required
                  />
                  {errors.email && <div className="error-message">{errors.email}</div>}
                </div>
                
                <div className="form-group">
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                </div>
                
                <div className="form-group">
                  <textarea
                    name="education"
                    placeholder="Education Background *"
                    value={formData.education}
                    onChange={handleInputChange}
                    disabled={isLoading}
                    required
                  ></textarea>
                  {errors.education && <div className="error-message">{errors.education}</div>}
                </div>
                
                <div className="form-group">
                  <textarea
                    name="experience"
                    placeholder="Professional Experience"
                    value={formData.experience}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  ></textarea>
                </div>
                
                <div className="form-group">
                  <textarea
                    name="interests"
                    placeholder="Research Interests *"
                    value={formData.interests}
                    onChange={handleInputChange}
                    disabled={isLoading}
                    required
                  ></textarea>
                  {errors.interests && <div className="error-message">{errors.interests}</div>}
                </div>
                
                <div className="form-group">
                  <textarea
                    name="additional"
                    placeholder="Additional Information"
                    value={formData.additional}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  ></textarea>
                </div>
                
                <button type="submit" className="submit-button" disabled={isLoading}>
                  {isLoading ? "Sending..." : "Submit Application"}
                  <FaPaperPlane />
                </button>
              </form>
            </div>
            
            {/* Testimonials Slider */}
            <div className="testimonial-slider">
              <div className="testimonial-container">
                <button
                  className="slider-arrow prev-arrow"
                  onClick={prevTestimonial}
                >
                  <FaArrowLeft />
                </button>
                
                <div className="testimonial-card">
                  <RiDoubleQuotesL className="quote-icon" />
                  <p className="testimonial-text">
                    {testimonials[currentTestimonial].text}
                  </p>
                  <div className="testimonial-author">
                    <h4>{testimonials[currentTestimonial].author}</h4>
                    <p>{testimonials[currentTestimonial].role}</p>
                  </div>
                  
                  <div className="slider-dots">
                    {testimonials.map((_, index) => (
                      <button
                        key={index}
                        className={`slider-dot ${
                          index === currentTestimonial ? "active" : ""
                        }`}
                        onClick={() => setCurrentTestimonial(index)}
                      ></button>
                    ))}
                  </div>
                </div>
                
                <button
                  className="slider-arrow next-arrow"
                  onClick={nextTestimonial}
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default CogradFellowship; 