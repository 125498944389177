import React, { useEffect } from "react";
import "./HeroSection.scss";
import bigFileHomeRightOpen from "../assets/file-home-right-open.webp";
import bigFileHomeLeftOpen from "../assets/file-home-left-open.webp";
import homeLeftMob from "../assets/heroSection/home-hero-left.webp";
import homeRightMob from "../assets/heroSection/home-hero-right.webp";
import innerMiddle from "../assets/middle.webp";
import innerLeft from "../assets/inner-left.webp";
import innerRight from "../assets/inner-right.webp";
import Line7 from "../assets/svg/Line 7.svg";
import Rect382 from "../assets/svg/Rectangle 382.svg";
import ruralLeft from "../assets/heroSection/bottom-down-left.webp"; 
import ruralright from "../assets/heroSection/bottom-down-right.webp";
import studMiddle from "../assets/heroSection/most-bottom.webp"; 
import HeroTagWithBorderBeam from "./HeroTagWithBorderBeam";
import AOS from "aos";
import "aos/dist/aos.css";
import { ArrowUpRight } from 'iconoir-react';


const HeroSection = () => {
  useEffect(() => {
    AOS.init({ duration: 200 });
    AOS.refresh();
  }, []);

  return (
    <div className="hero-section-container">
      <section className="hero">
        <h1>Engage . Enhance . Empower </h1>
        <h2>Building next-generation affordable schools for Bharat</h2>
        <HeroTagWithBorderBeam/>
        <button
            className="feature-button-home"
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSffTQsBZwZizYfSGFD0EBk0wswkqi1sgmr5AeOi_xVymPT80w/viewform",
                "_blank"
              )
            }
          >
            <span>Cograd Fellowship Program</span>
            <ArrowUpRight width={16} height={16} />
          </button>
        <div className="cta-buttons">
          <button className="teach-with-ai" onClick={() => window.open("https://teach.cograd.in/login/", "_blank")}>
            Teach with Medha AI <ArrowUpRight/>
          </button>
          
          {/* <button className="watch-video" onClick={() => window.open("https://youtu.be/QtDKl6HDf2g", "_blank")}>
            <FaPlay style={{ marginRight: 10 }} /> 
            Watch Video
          </button> */}
        </div>

        <div className="statistics mobile-none">
          <div className="outer-left" data-aos="fade-right">
            <div className="top-outer-left-box">
              <img src={bigFileHomeRightOpen} alt="Computers and AI for rural students" />
              <img src={Line7} alt="Computers and AI for rural students" className="line7" />
              <div>
                <h2>10%</h2>
                <p>
                  Only 10% students in rural schools have access to computers
                  and AI.
                </p>
              </div>
            </div>
            <div className="bottom-outer-left-box">
              <a
                href="https://blogs.cisco.com/csr/thingqbator-creating-an-education-ecosystem-to-nurture-innovation"
                target="_blank"
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                How we make Schools
                <br /> Innovative?
              </a>
            </div>
          </div>

          <div className="middle">
            <div className="inner-left" data-aos="fade-up">
              <img src={innerLeft} alt="How cograd helps with students engagement" />
              <div>
                <p>Engaging Students</p>
              </div>
            </div>
            <div className="inner-middle" data-aos="fade-up">
              <img src={innerMiddle} alt="Smart education with technology and AI" />
              <div>
                <p>
                  Enhancing lives and creating impacts through digitalization.
                </p>
                {/* <button className="teach-with-ai">Teach with VR</button> */}
              </div>
            </div>
            <div className="inner-left" data-aos="fade-up">
              <img src={innerRight} alt="How cograd empowers teachers with modern tech and AI" />
              <div>
                <p>Empowering Teachers</p>
              </div>
            </div>
          </div>

          <div className="outer-right" data-aos="fade-left">
            <div className="top-outer-right-box">
              <img src={bigFileHomeLeftOpen} alt="Financial Constraints of rural students" />
              <img src={Rect382} alt="Financial Constraints of rural students" className="rect382" />
              <div>
                <h2>28%</h2>
                <p>
                  28% students of Middle School drops out due to lack of Quality
                  Schools and Financial Constraints.<br/>
                  <i>as per ASER, 2023</i>
                </p>
              </div>
            </div>
            <div className="bottom-outer-right-box">
              <a
                href="https://yourstory.com/hindi/this-startup-is-giving-hi-tech-education-to-children-in-villages-cograd"
                target="_blank"
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                How We make Schools <br />
                Affordable?
              </a>
            </div>
          </div>
        </div>

        <div className="stat-mobile mobile-only"> 
          <div className="photo-grid row">
                        <div className="photo-card col-6">
                            <div className="card-left" data-aos="fade-right">
                                  <div className="top-outer-left-card">
                                        <img src={homeLeftMob} alt="10% students in rural schools have access to computers and AI" onContextMenu={(e) => e.preventDefault()} />
                                  </div>
                              </div>  
                        </div>
                        <div className="photo-card col-6">
                            <div className="card-right" data-aos="fade-left">
                                  <div className="top-outer-right-card">
                                        <img src={homeRightMob} alt="Lack of Quality Schools and Financial Constraints" onContextMenu={(e) => e.preventDefault()}/>
                                  </div>
                            </div>  
                        </div>
          </div>
          <div className="photo-grid row">
                        <div className="photo-card col-6">
                            <div className="card-left" data-aos="fade-right">
                                  <div className="top-outer-left-card-down">
                                        <img src={ruralLeft} alt="Impact on students" onContextMenu={(e) => e.preventDefault()}/>
                                  </div>
                              </div>  
                        </div>
                        <div className="photo-card col-6">
                            <div className="card-right" data-aos="fade-left">
                                  <div className="top-outer-right-card-down">
                                        <img src={ruralright} alt="Impact on teacher" onContextMenu={(e) => e.preventDefault()} />
                                  </div>
                            </div>  
                        </div>
          </div>
          <div className="bottom-down" data-aos="fade-up">
            <img src={studMiddle} alt="Impact on students" onContextMenu={(e) => e.preventDefault()}/>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
