import React, { useEffect, useRef, useState } from "react";
import { motion, useMotionValue, useAnimationFrame } from "framer-motion";
import "./WorkedWithVelocity.scss";
import com1 from "../assets/partners/l-adobe.webp";
import com2 from "../assets/partners/l-nasscom.webp";
import com3 from "../assets/partners/l-thingqbator.webp";
import com4 from "../assets/partners/l-DoSW.webp";
import com5 from "../assets/partners/l-dbse_11zon.webp";
import com6 from "../assets/partners/l-fitt-iid_11zon.webp";
import com7 from "../assets/partners/l-ihfc.webp";
import com8 from "../assets/partners/community-2.webp";

import home8 from "../assets/partners/partners/home8.webp";
import home9 from "../assets/partners/partners/home9.webp";
import home10 from "../assets/partners/partners/home10.webp";
import home11 from "../assets/partners/partners/home11.webp";
import home12 from "../assets/partners/partners/home12.webp";
import home13 from "../assets/partners/partners/home13.webp";
import home14 from "../assets/partners/partners/home14.webp";
import home15 from "../assets/partners/partners/home15.webp";
import home16 from "../assets/partners/partners/home16.webp";
import home17 from "../assets/partners/partners/home17.webp";

const communityMembers = [
  { id: 1, image: home8, alt: "Community Member 1" },
  { id: 2, image: home9, alt: "Community Member 2" },
  { id: 3, image: home10, alt: "Community Member 3" },
  { id: 4, image: home11, alt: "Community Member 4" },
  { id: 5, image: home12, alt: "Community Member 5" },
  { id: 6, image: home13, alt: "Community Member 6" },
  { id: 7, image: home14, alt: "Community Member 7" },
  { id: 7, image: home15, alt: "Community Member 7" },
  { id: 7, image: home16, alt: "Community Member 7" },
  { id: 7, image: home17, alt: "Community Member 7" },
];

export default function WorkedWithVelocity() {
  const x = useMotionValue(0);
  const duplicatedMembers = [...communityMembers, ...communityMembers];
  const containerRef = useRef(null);
  const paused = useRef(false);
  const speed = 100;  
  const [resetX, setResetX] = useState(0);

  const updateResetX = () => {
    if (containerRef.current) {
      setResetX(containerRef.current.scrollWidth / 2);
    }
  };

  useEffect(() => {
    updateResetX();
    window.addEventListener("resize", updateResetX);
    const timeout = setTimeout(updateResetX, 500);
    return () => {
      window.removeEventListener("resize", updateResetX);
      clearTimeout(timeout);
    };
  }, []);

  useAnimationFrame((time, delta) => {
    if (!paused.current) {
      const moveBy = (speed * delta) / 1000;
      x.set(x.get() - moveBy);
      if (x.get() <= -resetX) {
        x.set(0);
      }
    }
  });

  const handleMouseEnter = () => {
    paused.current = true;
  };
  const handleMouseLeave = () => {
    paused.current = false;
  };
  const handleClick = () => {
    paused.current = !paused.current;
  };

  return (
    <section className="community">
      <h2>
        <span className="h2-highlight">We Have </span>Worked With
      </h2>
      <div
        className="carousel-wrapper"
        style={{ overflow: "hidden" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <motion.div
          className="marquee"
          ref={containerRef}
          style={{ x, display: "flex" }}
        >
          {duplicatedMembers.map((member, index) => (
            <div key={index} style={{ flexShrink: 0 }}>
              <img
                src={member.image}
                alt={member.alt}
                className="carousel-img"
                onLoad={updateResetX}
              />
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}
