import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { X, Airplay } from 'lucide-react'; 


const WeblinearBentoGallery = ({ mediaItems: initialMediaItems, title, description }) => {
  
  const [selectedItem, setSelectedItem] = useState(null);
  const [items] = useState(initialMediaItems);
  const [scrollPaused, setScrollPaused] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  //DN: Ensure even distribution between rows
  const distributeItems = () => {
  const itemCount = items.length;
  const halfCount = Math.ceil(itemCount / 2);
  
  const topRowItems = items.slice(0, halfCount);
  const bottomRowItems = items.slice(halfCount);
  
  //DN: If odd number of items, duplicate last item of bottom row to maintain symmetry
    if (topRowItems.length > bottomRowItems.length && bottomRowItems.length > 0) {
      bottomRowItems.push(bottomRowItems[bottomRowItems.length - 1]);
    }
    
    return { topRowItems, bottomRowItems };
  };

  const { topRowItems, bottomRowItems } = distributeItems();

  //DN: Animation controls for the marquee rows.
  const controlsTop = useAnimation();
  const controlsBottom = useAnimation();

  //DN: Update container width on resize
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

  //DN:  Initial width calculation
    updateWidth();

  //DN: Add event listener for window resize
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (containerWidth === 0) return;

    if (!scrollPaused) {
      //DN: Adjust animation speed based on container width
      const durationFactor = Math.max(10, Math.min(30, containerWidth / 50));
      
      //DN: Animate top row from 0 to -containerWidth
      controlsTop.start({
        x: [0, -containerWidth],
        transition: {
          x: { repeat: Infinity, repeatType: 'loop', ease: 'linear', duration: durationFactor },
        },
      });
      
      //DN: Animate bottom row from -containerWidth to 0 (right to left)
      controlsBottom.start({
        x: [-containerWidth, 0],
        transition: {
          x: { repeat: Infinity, repeatType: 'loop', ease: 'linear', duration: durationFactor },
        },
      });
    } else {
      controlsTop.stop();
      controlsBottom.stop();
    }
  }, [scrollPaused, controlsTop, controlsBottom, containerWidth]);

  //DN: Internal MediaItem component renders an image or video with auto-play logic.
  const MediaItem = ({ item, className, onClick, isModal = false, isThumbnail = false }) => {
  const videoRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [isBuffering, setIsBuffering] = useState(true);

  //DN: Use the provided url or fallback to the first element of the images array.
    const source =
      item.url || (item.images && item.images.length > 0 && item.images[0]) || '';

    useEffect(() => {
      const options = { root: null, rootMargin: '50px', threshold: 0.1 };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => setIsInView(entry.isIntersecting));
      }, options);
      if (videoRef.current) observer.observe(videoRef.current);
      return () => {
        if (videoRef.current) observer.unobserve(videoRef.current);
      };
    }, []);

    useEffect(() => {
      let mounted = true;
      const handleVideoPlay = async () => {
        if (!videoRef.current || !isInView || !mounted) return;
        try {
          if (videoRef.current.readyState >= 3) {
            setIsBuffering(false);
            await videoRef.current.play();
          } else {
            setIsBuffering(true);
            await new Promise((resolve) => {
              if (videoRef.current) videoRef.current.oncanplay = resolve;
            });
            if (mounted) {
              setIsBuffering(false);
              await videoRef.current.play();
            }
          }
        } catch (error) {
          console.warn("Video playback failed:", error);
        }
      };

      if (isInView) {
        handleVideoPlay();
      } else if (videoRef.current) {
        videoRef.current.pause();
      }
      return () => {
        mounted = false;
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.removeAttribute('src');
          videoRef.current.load();
        }
      };
    }, [isInView]);

    //DN: Set dimensions based on whether this is a modal view, thumbnail, or gallery item
    let dimensions = {};
    
    if (isThumbnail) {
      dimensions = { width: '40px', height: '40px' }; // Smaller thumbnails in the dock
    } else if (!isModal) {
      dimensions = { width: '200px', height: '120px' }; // Regular gallery items
    }

    //DN: For modal, don't set fixed dimensions to use original size
    if (item.type === 'video') {
      return (
        <div 
          className={`${className} position-relative`} 
          onClick={onClick}
          style={dimensions}
        >
          <video
            ref={videoRef}
            className="w-100 h-100"
            playsInline
            muted
            loop
            preload="auto"
            style={{
              objectFit: 'cover',
              opacity: isBuffering ? 0.8 : 1,
              transition: 'opacity 0.2s',
              borderRadius: isThumbnail ? '4px' : '8px',
              ...dimensions
            }}
          >
            <source src={source} type="video/mp4" />
          </video>
          {isBuffering && (
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
              style={{ background: 'rgba(0,0,0,0.1)', borderRadius: isThumbnail ? '4px' : '8px' }}
            >
              <div className={`spinner-border ${isThumbnail ? 'spinner-border-sm' : ''} text-light`} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      );
    }

    return (
    
      <div 
        style={{ 
          overflow: 'hidden',
          borderRadius: isThumbnail ? '4px' : '8px',
          ...dimensions
        }}
        className={isModal ? "w-100" : ""}
      >
        <img
          src={source}
          alt={item.title || 'Gallery item'}
          className={className}
          onClick={onClick}
          loading="lazy"
          style={{ 
            objectFit: isModal ? 'contain' : 'cover', 
            cursor: 'pointer', 
            borderRadius: isThumbnail ? '4px' : '8px',
            ...dimensions
          }}
        />
      </div>
    );
  };

  // Internal GalleryModal component shows the selected media in a modal with a draggable dock.
  const GalleryModal = ({ selectedItem, isOpen, onClose, setSelectedItem, mediaItems }) => {
    if (!isOpen) return null;

    return (
      <>
        <motion.div
          initial={{ scale: 0.98 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.98 }}
          transition={{ type: 'spring', stiffness: 400, damping: 30 }}
          className="modal show d-block"
          onClick={onClose}
          style={{ background: 'rgba(0, 0, 0, 0.8)', zIndex: 1050 }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content bg-transparent border-0">
              <div className="modal-body p-0 position-relative">
                <MediaItem item={selectedItem} className="w-100" onClick={onClose} isModal={true} />
                {(selectedItem.title || selectedItem.desc) && (
                  <div
                    className="position-absolute w-100"
                    style={{
                      bottom: 0,
                      padding: '1rem',
                      background: 'linear-gradient(to top, rgba(0,0,0,0.5), transparent)',
                    }}
                  >
                    {selectedItem.title && <h5 className="text-white">{selectedItem.title}</h5>}
                    {selectedItem.desc && <p className="text-white-50">{selectedItem.desc}</p>}
                  </div>
                )}
                <motion.button
                  className="btn btn-light position-absolute"
                  style={{ top: '10px', right: '10px' }}
                  onClick={onClose}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <X size={16} />
                </motion.button>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          drag
          dragMomentum={false}
          className="position-fixed"
          style={{ left: '50%', bottom: '20px', transform: 'translateX(-50%)', zIndex: 1060 }}
        >
          <motion.div className="bg-dark bg-opacity-75 rounded px-2 py-1 d-flex" style={{ backdropFilter: 'blur(8px)' }}>
            {mediaItems.map((item) => (
              <motion.div
                key={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedItem(item);
                }}
                className={`me-1 ${selectedItem.id === item.id ? 'border border-info border-2 rounded' : ''}`}
                style={{ width: '40px', height: '40px', overflow: 'hidden', cursor: 'pointer' }}
                whileHover={{ scale: 1.1 }}
              >
                <MediaItem 
                  item={item} 
                  className="w-100 h-100" 
                  isThumbnail={true}
                />
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </>
    );
  };

  //DN: Create a duplicate array of the same items to ensure smooth transitions
  const createDuplicateArray = (items) => {
  //DN: Create enough duplicates to fill the screen width at least 3 times for smoother scrolling
    const minDuplicates = Math.ceil((containerWidth * 3) / (200 * Math.max(1, items.length)));
    let duplicatedItems = [];
    for (let i = 0; i < minDuplicates; i++) {
      duplicatedItems = [...duplicatedItems, ...items];
    }
    return duplicatedItems;
  };

  return (
    <div 
      ref={containerRef}
      style={{
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        margin: '0 auto',
        position: 'relative',
        padding: '50px 0 22px 0',
      }}
    >
      {/* DN: Only render title/description section if either value is provided */}
      {(title || description) && (
        <div className="text-center mb-4" id="sti">
          {/* // DN: Optionally use default css {title && <h2 style={{ fontSize: '2rem', fontWeight: '600', marginBottom: '1rem' }}>{title}</h2>} */}
          {title && <h2 className='bentogallery-title'>{title}</h2>}
          {description && <p style={{ fontSize: '1.1rem', color: '#666' }}>{description}</p>}
        </div>
      )}
      <AnimatePresence mode="wait">
        {selectedItem ? (
          <GalleryModal
            selectedItem={selectedItem}
            isOpen={true}
            onClose={() => {
              setSelectedItem(null);
              setScrollPaused(false);
            }}
            setSelectedItem={setSelectedItem}
            mediaItems={items}
          />
        ) : (
          containerWidth > 0 && (
            <div>
              {/* //DN: Top row of marquee */}
              <div
                style={{
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                <motion.div
                  className="d-flex"
                  onMouseEnter={() => setScrollPaused(true)}
                  onMouseLeave={() => setScrollPaused(false)}
                  animate={controlsTop}
                  style={{
                    display: 'flex',
                    width: 'fit-content',
                  }}
                >
                  {createDuplicateArray(topRowItems).map((item, index) => (
                    <motion.div
                      key={`top-${index}-${item.id}`}
                      style={{
                        width: '200px',
                        height: '120px',
                        flexShrink: 0,
                        cursor: 'pointer',
                        margin: '0px 8px 0px 8px',
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '8px'
                      }}
                      whileHover={{ scale: 1.05 }}
                      onClick={() => {
                        setScrollPaused(true);
                        setSelectedItem(item);
                      }}
                    >
                      <MediaItem item={item} className="w-100 h-100" />
                      {/* //DN:  Only show title/description overlay if either exists over image elements */}
                      {(item.title || item.desc) && (
                        <motion.div
                          className="position-absolute w-100"
                          style={{
                            bottom: 0,
                            left: 0,
                            padding: '0.5rem',
                            background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
                            borderRadius: '0 0 8px 8px',
                          }}
                          initial={{ opacity: 0 }}
                          whileHover={{ opacity: 1 }}
                          transition={{ duration: 0.2 }}
                        >
                          {item.title && <h6 className="text-white mb-0">{item.title}</h6>}
                          {item.desc && (
                            <p className="text-white-50 mb-0" style={{ fontSize: '0.8rem' }}>
                              {item.desc}
                            </p>
                          )}
                        </motion.div>
                      )}
                    </motion.div>
                  ))}
                </motion.div>
              </div>

              {/* //DN: Bottom row of marquee */}
              <div
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <motion.div
                  className="d-flex"
                  onMouseEnter={() => setScrollPaused(true)}
                  onMouseLeave={() => setScrollPaused(false)}
                  animate={controlsBottom}
                  style={{
                    display: 'flex',
                    width: 'fit-content',
                  }}
                >
                  {createDuplicateArray(bottomRowItems).map((item, index) => (
                    <motion.div
                      key={`bottom-${index}-${item.id}`}
                      style={{
                        width: '200px',
                        height: '120px',
                        flexShrink: 0,
                        cursor: 'pointer',
                        margin: '0px 8px 0px 8px',
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '8px'
                      }}
                      whileHover={{ scale: 1.05 }}
                      onClick={() => {
                        setScrollPaused(true);
                        setSelectedItem(item);
                      }}
                    >
                      <MediaItem item={item} className="w-100 h-100" />
                      {/* //DN:  Only show title/description overlay if either exists over image elements */}
                      {(item.title || item.desc) && (
                        <motion.div
                          className="position-absolute w-100"
                          style={{
                            bottom: 0,
                            left: 0,
                            padding: '0.5rem',
                            background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
                            borderRadius: '0 0 8px 8px',
                          }}
                          initial={{ opacity: 0 }}
                          whileHover={{ opacity: 1 }}
                          transition={{ duration: 0.2 }}
                        >
                          {item.title && <h6 className="text-white mb-0">{item.title}</h6>}
                          {item.desc && (
                            <p className="text-white-50 mb-0" style={{ fontSize: '0.8rem' }}>
                              {item.desc}
                            </p>
                          )}
                        </motion.div>
                      )}
                    </motion.div>
                  ))}
                </motion.div>
              </div>
            </div>
          )
        )}
      </AnimatePresence>
      <button 
        onClick={() => window.open('https://www.sonyliv.com/shows/shark-tank-india-1700000741/smart-learning-healthy-eating-and-wellness-1090469757', '_blank')}
        style={{ backgroundColor: '#0041e8', color: '#ffffff', padding: '8px 20px', fontSize: '15px', borderRadius: '5px', textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', cursor: 'pointer', border: '1px solid #0041e8', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto', transition: 'transform 0.3s, background-color 0.3s' }}
        onMouseOver={(e) => { e.currentTarget.style.transform = 'scaleX(1.1)'; e.currentTarget.style.backgroundColor = '#0056b3'; }}
        onMouseOut={(e) => { e.currentTarget.style.transform = 'scaleX(1)'; e.currentTarget.style.backgroundColor = '#0041e8'; }}
      >
        Watch Now
        <Airplay size={16} />
      </button>
    </div>
    
  );
};

export default WeblinearBentoGallery;


// WeblinearBentoGallery – Developer Release Note (Weblinear V25)

// At Weblinear, we are committed to developing and distributing high-quality components that empower developers to build innovative and visually stunning web experiences.

// About WeblinearBentoGallery
// WeblinearBentoGallery is a versatile marquee component featuring a Bento Grid design. It enables seamless integration of images and videos into a dynamic, visually appealing layout.

// Installation & Usage
// 1. Run this command in terminal  npm install framer-motion lucide-react or npx install framer-motion lucide-react
// 2. To use this component in your project, simply copy, paste, and import it into your parent component.

// Importing WeblinearBentoGallery in a Parent Component
// Ensure you pass the required props as shown in the example below:

//Parent Component jsx:
// <WeblinearBentoGallery 
//   mediaItems={mediaItems} 
//   title="Weblinear Bento Gallery Marquee" 
//   description="An open-source bento gallery marquee developed and distributed by Weblinear V25" 
// />
// Media Items Structure
// mediaItems should be an array of objects containing image or video data, structured as follows:

// Example (Images & Videos)
// const mediaItems = [
//   { id: 1, type: 'image', title: 'Image One', desc: 'Sample description', images: [image1] },
//   { id: 2, type: 'image', title: 'Image Two', desc: 'Sample description', images: [image2] },
//   { id: 3, type: 'video', title: 'Video Sample', desc: 'Sample description', url: 'https://youtube.com/your-media' },
//   { id: 4, type: 'image', title: 'CDN Image', desc: 'Sample description', url: 'https://cdn.cloudflare.com/your-media' }
// ];

// CSS Guidelines:
// The component leverages Bootstrap or TailwindCSS core utility classes.
// For non-generic styles, we utilize inline CSS to ensure a hassle-free integration into any project.

//Close Icon
//Used lucide-react here, you can use any other alternative too.

// WeblinearBentoGallery is designed to be lightweight, efficient, and easy to use, helping developers build modern, aesthetically pleasing web designs effortlessly.