import React from "react";
import BorderBeam from "./Animations/BorderBeam";

const HeroTagWithBorderBeam = () => {
  const containerStyle = {
    position: "relative",
    display: "inline-block", 
    padding: "5px",  
    borderRadius: "10px",  
    border: "1px solid transparent",  
  };
  const textStyle = {
    position: "relative",
    zIndex: 1,
  };

  return (
    <div style={containerStyle}>
      <BorderBeam
        size={90}
        duration={6}
        delay={0}
        colorFrom="#0041e8"
        colorTo="#86a3e9bd"
        reverse={false}
        initialOffset={0}
      />
      <span 
        className="hero-tag" 
        style={{...textStyle, cursor: 'pointer'}}
        onClick={() => {
          document.getElementById('sti')?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        As seen on{" "}
        <span className="hero-tag-bold">Shark Tank India</span>
      </span>
    </div>
  );
};

export default HeroTagWithBorderBeam;