import React, { useEffect, useState, useRef } from "react";
import "./Home.scss";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import PopupModal from "../components/PopupModal";
import WorkedWithVelocity from "../components/WorkedWithVelocity";
import off1 from "../assets/key-offerings/cs-5.webp";
import off2 from "../assets/key-offerings/cs-6.webp";
import off3 from "../assets/key-offerings/cs-7.webp";
import off4 from "../assets/key-offerings/cs-8.webp";
import off5 from "../assets/key-offerings/cs-9.webp";
import off6 from "../assets/key-offerings/su-1.webp";
import off7 from "../assets/key-offerings/su-2.webp";
import off8 from "../assets/key-offerings/su-3.webp";
import off9 from "../assets/key-offerings/su-4.webp";
import off10 from "../assets/key-offerings/su-5.webp";

// STI Images
import sti1 from "../assets/STI/sti1.webp";
import sti2 from "../assets/STI/sti2.webp";
import sti3 from "../assets/STI/sti3.webp";
import sti4 from "../assets/STI/sti4.webp";
import sti5 from "../assets/STI/sti5.webp";
import sti6 from "../assets/STI/sti6.webp";
import sti7 from "../assets/STI/sti7.webp";
import sti8 from "../assets/STI/sti8.webp";

import aiImage from "../assets/innovation/ai-for-teachers.webp";
import smartClassesImage from "../assets/innovation/smart-classes.webp";
import schoolUpgrade from "../assets/services/capacity-building_11zon.webp";
import skillDevelopmentImage from "../assets/innovation/collab-emerging-tech_11zon.webp";
import Projects from "../components/Projects";
import SuccessStories from "../components/SuccessStories";
import Testimonials from "../components/Testimonials";
import AwardsCarousel from "../components/AwardsCarousel";
import WeblinearBentoGallery from "../components/WeblinearBentoGalleryMarquee";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { CountUp } from "countup.js";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ArrowUpRight } from 'iconoir-react';

const mediaItems = [
  { id: 1, type: 'image',  images: [sti1] },
  { id: 2, type: 'image',  images: [sti2] },
  { id: 3, type: 'image',  images: [sti3] },
  { id: 4, type: 'image',  images: [sti4] },
  { id: 5, type: 'image',  images: [sti5] },
  { id: 6, type: 'image',  images: [sti6] },
  { id: 7, type: 'image',  images: [sti7] },
  { id: 8, type: 'image',  images: [sti8] },
];

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOffering, setSelectedOffering] = useState("Cograd Schools");
  const hasSeenPopup = useRef(false);

   // Check if user has seen the popup in this session
   useEffect(() => {
    const popupSeen = sessionStorage.getItem('cogradPopupSeen');
    if (!popupSeen && !hasSeenPopup.current) {
      // Small delay to make sure the page loads first
      const timer = setTimeout(() => {
        setShowPopup(true);
        hasSeenPopup.current = true;
      }, 1500);
      
      return () => clearTimeout(timer);
    }
  }, []);

  // Handle popup close
  const handleClosePopup = () => {
    setShowPopup(false);
    // sessionStorage.setItem('cogradPopupSeen', 'true');
  };

  const offeringsData = {
    "Cograd Schools": {
      images: [off4, off1, off2, off3, off5],
      description: [
        "At Cograd Schools, we provide a holistic educational experience by integrating modern technology and traditional teaching methods.",
        "Our programs focus on critical thinking, creativity, and collaboration to prepare students for the future.",
      ],
    },
    "Up-Skilling Programs": {
      images: [off10, off6, off7, off8, off9],
      description: [
        "Our Up-Skilling Programs are designed to equip individuals with the latest skills in various fields such as AI, data science, and digital marketing.",
        "We run tailored programmes for Teachers and Administrators as per NEP-2020 and NCF-2023.",
      ],
    },
  };
 


  useEffect(() => {
    const options = {
      duration: 5, // duration in seconds
      separator: ",", // thousands separator
    };

    const updateWithPlusSign = (id, value) => {
      const countUp = new CountUp(id, value, options);
      if (!countUp.error) {
        countUp.start(() => {
          document.getElementById(id).innerHTML += "+";
        });
      } else {
        console.error(countUp.error);
      }
    };

    updateWithPlusSign("workshops", 200);
    updateWithPlusSign("students", 30000);
    updateWithPlusSign("successStories", 2000);
    updateWithPlusSign("schools", 25);
  }, []);

  const handleClick = (offering) => {
    setSelectedOffering(offering);
    if (circleContainerRef.current) {
      circleContainerRef.current.classList.remove('visible');
      setTimeout(() => {
        circleContainerRef.current.classList.add('visible');
      }, 500);  
    }
    if (contentRef.current) {
      contentRef.current.classList.remove('visible');
      setTimeout(() => {
        contentRef.current.classList.add('visible');
      }, 500);  
    }
  };


  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4, // Adjust the number of slides shown per screen
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleButtonClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSffTQsBZwZizYfSGFD0EBk0wswkqi1sgmr5AeOi_xVymPT80w/viewform?embedded=true",
      "_blank"
    );
  };

  const knowMoreClick = () => {
    window.location.href = "/services";
  };

  // const featuresRef = useRef([]);
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           entry.target.classList.add('visible');
  //           observer.unobserve(entry.target);
  //         }
  //       });
  //     },
  //     { threshold: 0.1 }
  //   );

  //   featuresRef.current.forEach((feature) => {
  //     if (feature) {
  //       observer.observe(feature);
  //     }
  //   });

  //   return () => {
  //     if (featuresRef.current) {
  //       featuresRef.current.forEach((feature) => {
  //         if (feature) {
  //           observer.unobserve(feature);
  //         }
  //       });
  //     }
  //   };
  // }, []);

  const circleContainerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (circleContainerRef.current || contentRef.current) {
      if (circleContainerRef.current) observer.observe(circleContainerRef.current);
      if (contentRef.current) observer.observe(contentRef.current);
    }

    return () => {
      if (circleContainerRef.current) observer.unobserve(circleContainerRef.current);
      if (contentRef.current) observer.unobserve(contentRef.current);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const featuresRefScroll = useRef(null);
  useEffect(() => {
    const handleScroll = (event) => {
      if (featuresRefScroll.current) {
        featuresRefScroll.current.scrollLeft += event.deltaY;
      }
    };

    const scrollContainer = featuresRefScroll.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);


  return (
    <div className="home">
      <Helmet>
        <title>Cograd - Innovative and Affordable Education</title>
        <meta
          name="description"
          content="Cograd offers innovative and affordable education solutions. Discover our vision for accessible education, services, and partnership opportunities. Learn more about Cograd now."
        />
      </Helmet>
      <Header />
      <HeroSection />
       {/* Popup Modal */}
       <PopupModal isOpen={showPopup} onClose={handleClosePopup} />

      <section className="innovation">
        <div className="container">
          <h2>
            How Are We Innovating With{" "}
            <span className="highlight">Technology?</span>
          </h2>
          <div className="features" ref={featuresRefScroll}>
            <div className="feature visible">
              <img
                src={aiImage}
                alt="AI for Teachers"
                className="feature-image"
              />
              <div className="feature-content">
                <h3>AI for Teachers</h3>
                <p>
                  Our AI-powered tools assist teachers in creating personalized
                  lesson plans, grading assignments efficiently, and providing
                  real-time feedback to students.
                </p>
              </div>
            </div>
            <div className="feature">
              <img
                src={smartClassesImage}
                alt="Smart Classes"
                className="feature-image"
              />
              <div className="feature-content">
                <h3>Smart Classes</h3>
                <p>
                  Equipped with the latest technology, our smart classrooms
                  facilitate interactive and immersive learning experiences.
                </p><br></br>
              </div>
            </div>
            <div className="feature">
              <img
                src={skillDevelopmentImage}
                alt="Skill Development Programs"
                className="feature-image"
              />
              <div className="feature-content mb-1">
                <h3>Curriculum on Emerging Tech</h3>
                <p>
                  Our Curriculum on Emerging Tech prepares students for the future
                  workforce with 10+ Courses in Emerging Technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <HomeSlickCarousel /> */}

      <section className="impact-metrics">
        <h2><span>Our</span> Impact</h2>
        <div className="matrics-container">
          <div className="metric counter-sepearator">
            <h3 id="workshops">200+</h3>
            <p>Workshops<br></br> Organized</p>
          </div>
          <div className="metric counter-sepearator">
            <h3 id="students">30,000+</h3>
            <p>Students<br></br> Impacted</p>
          </div>
          <div className="metric counter-sepearator">
            <h3 id="successStories">2000+</h3>
            <p>Success<br></br> Stories</p>
          </div>
          <div className="metric haazarpx-seperator">
            <h3 id="schools">25+</h3>
            <p>Schools<br></br> Collaborated</p>
          </div>
        </div>
      </section>
      <WorkedWithVelocity/>
      <section className="key-offerings">
        
          <div className="key-offering-heading">
            <h2><span className="h2-highlight">Our </span>Key Offerings</h2>
                    
                    <div className="highlights">
                      <span
                        className={selectedOffering === "Cograd Schools" ? "active" : ""}
                        onClick={() => handleClick("Cograd Schools")}
                      >
                        Cograd Schools
                      </span>
                      <span
                        className={
                          selectedOffering === "Up-Skilling Programs" ? "active" : ""
                        }
                        onClick={() => handleClick("Up-Skilling Programs")}
                      >
                        Up-Skilling Programs
                      </span>
                    </div>

          </div>



              <div className="container">
              <div className="key-offering-contents">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="circle-container" ref={circleContainerRef}>
                          {isMobile ? (
                            <div className="offering-img-boxed">
                              <img src={offeringsData[selectedOffering].images[0]} alt="Our Offering" />
                            </div>
                          ) : (
                            offeringsData[selectedOffering].images.map((image, index) => (
                              <div className="offering-img" key={index}>
                                <img src={image} alt={`Offering ${index + 1}`} />
                              </div>
                            ))
                          )}
                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <div className="content" ref={contentRef}>
                        <div className="details">
                          <ul className="mt-3">
                            {offeringsData[selectedOffering].description.map((item, index) => (
                              <li className="mb-2" key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                        <button className="know-more-button" onClick={knowMoreClick}>Know more about our services <ArrowUpRight/></button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
      </section>

      <Projects />

      <h2 className="story-heading">
        <span className="story-heading-span">Stories of </span>Success, Hope and Transformation
      </h2>

      <SuccessStories />
      <AwardsCarousel />
      <Testimonials />
      <WeblinearBentoGallery
        mediaItems={mediaItems}
        title="As Seen on Shark Tank India"
        description=""
        
      />
    

      {/* <section className="gallery-cta">
        <h2>Visit our Gallery to See How We Work!</h2>
        <button>Visit</button>
      </section> */}

      <section className="collaboration-cta mt-5">
        <div className="cta-content">
          <div className="cta-image">
            <img src={schoolUpgrade} alt="Upgrade your school" />
          </div>
          <div className="cta-text">
            <h2>Upgrade your school</h2>
            <p>
              Discover innovative solutions to elevate your educational
              institution with Cograd. We provide cutting-edge technology,
              personalized support, and tailored programs to enhance teaching,
              learning, and student outcomes.
            </p>
            <button onClick={handleButtonClick}>
              {/* Contact Us for Collaboration */}
              Upgrade Now <ArrowUpRight/>
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
